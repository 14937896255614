<template>
  <div class="vg_wrapper">
    <el-form ref="desiForm" :model="desiForm" :rules="rules" label-width="120px" size="mini">
      <add-header @submit="submit('desiForm')" @cancel="cancel" :stateFlag="true" :formData="desiForm">
        <!-- <template slot-scope="scope" slot="input">
        <el-form-item label="委托设计单号" prop="dequ_no">
          <el-input maxlength="30" v-model="scope.desiForm.dequ_no" disabled placeholder="请填写委托设计单号" show-word-limit>
            <template slot="append">
              <el-link type="primary" @click="dialogVisible = true" class="vg_cursor_hander">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </template> -->
      </add-header>
      <el-row>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="委托人" prop="dequ_stff_id">
                <el-select filterable v-model="desiForm.dequ_stff_id" placeholder="请选择委托人">
                  <el-option v-for="item in desiStffListCopy" :key="item.stff_id" :label="item.stff_name + '（' + item.dept_name + '）'" :value="item.stff_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="委托设计单号" prop="dequ_no">
                <el-input maxlength="30" v-model="desiForm.dequ_no" disabled placeholder="请选择委托设计单号" show-word-limit>
                  <template slot="append">
                    <el-link type="primary" @click="dialogVisible = true" class="vg_cursor_hander">双击选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="产品类别" prop="prod_type">
                <el-select @change="prodTypeChange" filterable v-model="desiForm.prod_type" placeholder="请选择产品类别">
                  <el-option v-for="item in prodTypeList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="产品属性" prop="prod_attr">
                <el-select @change="prodAttrChange" filterable v-model="desiForm.prod_attr" placeholder="请选择产品属性">
                  <el-option v-for="item in prodAttrList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="产品类别代码" prop="prod_type_code">
                <el-input v-model="desiForm.prod_type_code" disabled placeholder="请填写产品类别代码" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="产品属性代码" prop="prod_attr_code">
                <el-input v-model="desiForm.prod_attr_code" disabled show-word-limit placeholder="请填写产品属性代码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="我司货号" prop="prod_no">
                <el-input v-model="desiForm.prod_no" disabled placeholder="请填写我司货号" show-word-limit> </el-input>
                <div class="vg_9f9a9a">(保存后自动生成)</div>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="难易程度" prop="desi_rank">
                <el-select filterable v-model="desiForm.desi_rank" placeholder="请选择难易程度">
                  <el-option v-for="item in desiRankList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="设计人" prop="desi_stff_id">
                <el-select filterable v-model="desiForm.desi_stff_id" placeholder="请选择设计人">
                  <el-option v-for="item in desiStffList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="设计日期" prop="desi_date">
                <el-date-picker v-model="desiForm.desi_date" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="产品类型" prop="desi_type">
                <el-select filterable v-model="desiForm.desi_type" placeholder="请选择产品类型">
                  <el-option v-for="item in desiTypeList" :key="item.id" :label="item.value" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="老ERP货号" prop="old_prod_cust_no">
                <el-input v-model="desiForm.old_prod_cust_no" maxlength="20" placeholder="请填写老ERP货号" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24" class="vg_mb_5">
              <el-form-item label="产品名称" prop="prod_name">
                <el-input v-model="desiForm.prod_name" maxlength="20" show-word-limit placeholder="请填写产品名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="产品规格" prop="prod_spec">
                <el-input v-model="desiForm.prod_spec" type="textarea" maxlength="100" :rows="3" show-word-limit placeholder="请填写产品规格"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="8">
          <el-form-item label="产品主图" prop="imge_id">
            <el-upload
              class="upload-demo"
              drag
              ref="uploadMutiple"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <!-- <i class="el-icon-upload"></i> -->
              <imgPopover v-if="desiForm.imge_id" :imgeUrl="desiForm.imge_url" :imgeUrlO="desiForm.imge_urlO"> </imgPopover>
              <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
            </el-upload>
            <span>大小不能超过3MB</span>
            <div id="preview" @paste="handlePaste" class="vd_flex_right">
              <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="备注" prop="desi_remake">
            <el-input maxlength="255" v-model="desiForm.desi_remake" type="textarea" :rows="2" show-word-limit placeholder="请填写备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
      <el-dialog :title="title" :visible.sync="dialogVisible" width="70%">
        <DeqiList @childDequ="childDequ"></DeqiList>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { desiAPI } from '@api/modules/desi';
import { imgeAPI } from '@api/modules/imge';
import { stffAPI } from '@api/modules/staff';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import imgPopover from '@/views/component/imgPopover';
import DeqiList from './Componet/DequImport.vue';
import { getStff } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'DesiAddMain',
  components: {
    addHeader,
    inputUser,
    imgPopover,
    DeqiList
  },
  data() {
    return {
      rules: {
        prod_type: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_attr: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        desi_stff_id: [{ required: this.$cookies.get('userInfo').dept_id === 12, trigger: ['blur', 'change'], message: ' ' }],
        desi_date: [{ required: true, trigger: 'blur', message: ' ' }],
        dequ_stff_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_name: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_spec: [{ required: this.$cookies.get('userInfo').dept_id === 12, trigger: 'blur', message: ' ' }],
        desi_type: [{ required: true, trigger: 'blur', message: ' ' }],
        desi_rank: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      desiForm: {
        dequ_id: null,
        dequ_no: null,
        desi_no: null,
        desi_id: null,
        dequ_stff_id: null,
        prod_type: null,
        prod_type_code: null,
        prod_attr: '',
        prod_attr_code: null,
        prod_no: null,
        prod_name: null,
        prod_spec: null,
        desi_stff_id: '',
        desi_date: new Date().getTime(),
        desi_rank: null,
        desi_remake: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        desi_type: 1
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      dialogVisible: false,
      title: '委托设计单',
      prodTypeList: [],
      prodAttrList: [],
      desiStffList: [],
      desiTypeList: [
        { id: 1, value: '新款' },
        { id: 2, value: '老款' },
        { id: 3, value: '修改款' }
      ],
      desiRankList: [],
      desiStffListCopy: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  async mounted() {
    let syncData = this.$route.params.syncData;
    if (syncData) {
      this.desiForm = syncData;
      this.desiForm.desi_date *= 1000;
      return;
    }
    let { stff_id: currentStffId, dept_id } = this.$cookies.get('userInfo');
    this.desiForm.desi_stff_id = currentStffId;
    this.desiForm.dequ_stff_id = currentStffId;
    // let isDesginDept = (await getDept('设计', 'ids')).includes(dept_id);
    // let attr = '';
    // if (isDesginDept) attr = '自主设计';
    // else attr = '客户设计';
    this.desiForm.prod_attr = '自主设计';
    this.prodAttrChange('自主设计');
  },
  methods: {
    initData() {
      this.getProdTypeList();
      this.getProdAttrList();
      this.getDesiRankList();
      this.getDesiStffList();
      this.getDesiStffListCopy();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let desicopy = JSON.parse(JSON.stringify(this.desiForm));
      let desiForm = Object.assign(desicopy, staffForm);
      desiForm.desi_date = parseInt(new Date(desiForm.desi_date).getTime() / 1000);
      desiForm.personal = this.$cookies.get('userInfo').personal;
      post(desiAPI.addDesi, desiForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            // const permId = this.$route.query.perm_id
            // console.log(this.$route.query.key);
            const props = this.$route.query;
            this.$EventBus.$emit('close_current_tags');
            this.jump('/desi_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: props.perm_id,
                  form_id: res.data.data.form_id
                })
              )
            });
            // this.jump(`/desi_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
            this.resetForm('desiForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('desiForm');
          // this.$router.go(-1)
          this.jump('/desi_list');
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取产品类别
    getProdTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10004 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodTypeList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 获取产品属性
    getProdAttrList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodAttrList = res.data.data.form.optn_cntt_list;
            if (this.desiForm.prod_attr) {
              this.prodAttrChange(this.desiForm.prod_attr);
            }
          }
        })
        .catch(() => {});
    },
    // 获取稿件等级
    getDesiRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10002 })
        .then(res => {
          if (res.data.code === 0) {
            this.desiRankList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 获取设计人
    async getDesiStffList() {
      this.desiStffList = [...(await getStff('设计')), ...(await getStff('电商'))];
    },
    getDesiStffListCopy() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.desiStffListCopy = res.data.data;
          }
        })
        .catch(() => {});
    },
    // 更改产品类别赋值产品类别代码
    prodTypeChange(val) {
      this.prodTypeList.forEach(item => {
        if (item.param1 === val) {
          this.desiForm.prod_type_code = item.param2;
        }
      });
    },
    // 更改产品属性赋值产品属性代码
    prodAttrChange(val) {
      this.prodAttrList.forEach(item => {
        if (item.param1 === val) {
          this.desiForm.prod_attr_code = item.param2;
        }
      });
    },
    // 获取委托设计单号和委托人
    childDequ(value) {
      let val = JSON.parse(JSON.stringify(value));
      this.desiForm.dequ_id = val.dequ_id;
      this.desiForm.dequ_no = val.dequ_no;
      this.desiForm.dequ_stff_id = val.dequ_stff_id;
      //  this.desiForm.desi_stff_id = this.desiStffList.find(({ label }) => label === val.dequ_designer_name)?.value || '';
      this.dialogVisible = false;
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.desiForm.imge_id = res.data.imge_id;
        this.desiForm.imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
        this.desiForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 3;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 3MB!');
        return false;
      }
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.desiForm.dequ_id = null;
    },
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;
      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地');
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        this.$message.error('粘贴内容非图片');
        return;
      }
      this.$refs.uploadMutiple.handleStart(file); // 将粘贴过来的图片加入预上传队列
      this.$refs.uploadMutiple.submit(); // 提交图片上传队列
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
